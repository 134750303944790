"use client";

import { Layout } from "@/src/pattern-library";
import AppTopBar from "./AppTopBar";

const AppPageLayout = ({ children }: { children: React.ReactNode }) => (
  <Layout style={{ minHeight: "100vh" }}>
    <AppTopBar />
    {children}
  </Layout>
);

export default AppPageLayout;
