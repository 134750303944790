"use client";

import { supplierRoutes } from "@/src/constants/routes";
import { Namespace } from "@/src/i18n/namespace";
import { Button, Flex, Text, Title, theme } from "@/src/pattern-library";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useRedirectToHome } from "./useRedirectToHome";

export function NotFoundPage() {
  const tCommon = useTranslations(Namespace.COMMON);
  const {
    token: { sizeXS },
  } = theme.useToken();

  useRedirectToHome();

  return (
    <Flex
      vertical
      gap={sizeXS}
      align="center"
      justify="center"
      className="absolute inset-0 bg-[#F7F7FA]"
    >
      <Title style={{ margin: 0 }} level={5}>
        {tCommon("errorScreens.notFound.title")}
      </Title>
      <Text size="SM">{tCommon("errorScreens.notFound.description")}</Text>
      <Link href={supplierRoutes.home}>
        <Button size="small">{tCommon("navigation.backToMain")}</Button>
      </Link>
    </Flex>
  );
}
