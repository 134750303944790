"use client";

import React from "react";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { supplierRoutes } from "@/src/constants/routes";
import { segmentEvents } from "@/src/constants/segmentEvents";
import { Button, Layout, theme } from "@/src/pattern-library";
import { segmentClient } from "@/src/utils/segment";
import AppLanguageSelection from "./AppLanguageSelection";
import AppUserMenu from "./AppUserMenu";
import { Namespace } from "@/src/i18n/namespace";
import { HEADER_HEIGHT, Z_INDEX_APP_TOP_BAR } from "@/src/constants/layout";

const { Header } = Layout;

const AppTopBar = () => {
  const t = useTranslations(Namespace.COMMON);
  const {
    token: { colorBgContainer, sizeLG, colorBorderSecondary },
  } = theme.useToken();

  const handleHelpCentreClick = () => {
    segmentClient.track(segmentEvents.helpCenterClicked);
    window.open(supplierRoutes.helpCenter);
  };

  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: Z_INDEX_APP_TOP_BAR,
        height: HEADER_HEIGHT,
        width: "100%",
        display: "flex",
        background: colorBgContainer,
        alignItems: "center",
        paddingLeft: sizeLG,
        paddingRight: sizeLG,
        borderBottom: `1px solid ${colorBorderSecondary}`,
      }}
    >
      <div className="flex justify-between w-[100%]">
        <div className="flex items-center">
          <a href={supplierRoutes.home}>
            <Image
              className="mr-3"
              src="/images/trustana_logo_with_text.svg"
              alt="Trustana Logo"
              width={160}
              height={32}
            />
          </a>
        </div>
        <div className="flex gap-4">
          <AppLanguageSelection />
          <Button
            shape="round"
            icon={<QuestionCircleOutlined />}
            onClick={handleHelpCentreClick}
          >
            {t("navigation.helpCenter")}
          </Button>
          <AppUserMenu />
        </div>
      </div>
    </Header>
  );
};

export default AppTopBar;
